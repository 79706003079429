<template>
	<h1 class="page-title">{{ getTranslated(location, 'name', true) }}</h1>
	<h2 class="page-subtitle" v-if="location.name_la && getTranslated(location, 'name', true) && (location.name_la !== getTranslated(location, 'name', true))">({{location.name_la}})</h2>

	<div class="poem-field" v-if="connectedPersons.length">
		<div class="label">{{t.fields.connected_persons}}:</div>
		<ul>
			<li v-for="person in connectedPersons" :key="person.id">
				<RouterLink :to="'/person/'+person.id">
					<Popper :content="t.search.person" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
						<img src="/people.png" :alt="t.search.person" class="tooltip-image"/>
					</Popper>
					<span>{{getTranslated(person, 'name')}}</span>
				</RouterLink>
			</li>
		</ul>
	</div>

	<div class="poem-field" v-if="connectedPoems.length">
		<div class="label">{{t.fields.connected_poems}}:</div>
		<ul>
			<li v-for="poem in connectedPoems" :key="poem.id">
				<RouterLink :to="'/poem/'+poem.id">
					<div v-if="getVolumeTitle(poem)">
						<Popper :content="t.search.volume" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
							<img src="/volume.png" :alt="t.search.volume" class="tooltip-image"/>
						</Popper>
						<span>{{getVolumeTitle(poem)}}</span>
					</div>

					<div v-if="getPoemTitle(poem)">
						<Popper :content="t.search.poem" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
							<img src="/poem.png" :alt="t.search.poem" class="tooltip-image"/>
						</Popper>
						<span>{{getPoemTitle(poem)}}</span>
					</div>
				</RouterLink>
			</li>
		</ul>
	</div>

	<div class="poem-field" v-if="images.length">
		<div class="label">{{t.fields.images}}:</div>
		<div class="location-images">
			<img v-for="(image, index) in images" :key="image.id" @click="openGallery(index)" :src="backendUrl()+image.url" :alt="image.alternativeText ? image.alternativeText : ''"/>
		</div>
	</div>

	<vue-easy-lightbox
		:visible="showGallery"
		:imgs="getImageUrls()"
		:index="showImage"
		@hide="hideGallery"
		:loop="true"
	></vue-easy-lightbox>
</template>

<script>
import Popper from "vue3-popper";
import VueEasyLightbox from 'vue-easy-lightbox'
import {getArrayObject, isEmptyObject, isNumber} from "@/functions";

export default {
	components: {
		Popper,
		VueEasyLightbox
	},
	inject: ['t', 'locale', 'getTranslated', 'getOriginal'],
	name: 'PlaceView',
	props: {
		poems: Array,
		locations: Array,
		volumes: Array,
		people: Array,
	},
	data() {
		return {
			id: 0,
			location: {},
			showImage: null,
			showGallery: false,
			images: [],
		}
	},
	created() {
		this.getLocation();
	},
	computed: {
		connectedPersons() {
			let _this = this;
			let persons = [];

			this.people.forEach(function (people) {
				if(people.birth_location && (people.birth_location.id === _this.location.id)) {
					persons.push({
						...people
					});
				}
			});

			this.people.forEach(function (people) {
				if(people.study_locations && people.study_locations.length) {

					people.study_locations.forEach(function (location) {
						if (location.id === _this.location.id) {
							persons.push({
								...people
							});
						}
					});
				}
			});

			return persons;
		},
		connectedPoems() {
			let _this = this;
			let poems = [];

			this.poems.forEach(function (poem) {
				if(poem.volume && poem.volume.published_location && poem.volume.published_location.id === _this.location.id) {
					poems.push({
						...poem
					});
				}
			});

			return poems;
		},
		poemsSupervised() {
			let _this = this;
			let poems = [];

			this.poems.forEach(function (poem) {
				if(poem.volume && poem.volume.supervisor && poem.volume.supervisor.id === _this.person.id) {
					poems.push({
						...poem
					});
				}
			});

			return poems;
		}
	},
	methods: {
		backendUrl() {
			return process.env.VUE_APP_BACKEND_URL;
		},
		openGallery(index) {
			this.showGallery = true;
			this.showImage = index;
		},
		hideGallery() {
			this.showGallery = false;
		},
		getLocation() {
			if (this.$route.params && this.$route.params.id && isNumber(this.$route.params.id)) {
				this.id = Number(this.$route.params.id);

				this.location = {...getArrayObject(this.locations, 'id', this.id)};

				if (this.location.images && this.location.images.length) {
					this.images = [...this.location.images];
				}
			}

			if (isEmptyObject(this.location)) {
				this.$router.push('/404');
			}
		},
		getImageUrls() {
			let imageUrls = [];
			let _this = this;

			this.images.forEach(function (image) {
				imageUrls.push(_this.backendUrl() + image.url);
			});

			return imageUrls;
		},
		getPoemTitle(poem) {
			let title = "";

			if(this.getOriginal(poem, 'title')) {
				title = this.getOriginal(poem, 'title');
			}

			if(!title && poem.header) {
				title = this.getOriginal(poem.header, 'line');
			}

			return title;
		},
		getVolumeTitle(poem) {
			if(poem.volume) {
				return this.getOriginal(poem.volume, 'title');
			}

			return '';
		},
	}
};
</script>

<style scoped lang="scss">
	.poem-field {
		font-size: 18px;

		.label {
			color: #5b5b5b;
			font-size: 14px;
			display: block;
			width: 100%;
			line-height: 1;
		}

		a {
			color: #804010;
			transition: all 250ms ease-in-out;

			&:hover {
				color: #cb946c;
			}
		}

		+ .poem-field {
			margin-top: 15px;
		}
	}

	.location-images {
		display: flex;
		flex-flow: row;
		flex-direction: row;
		align-items: stretch;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 5px;

		img {
			width: auto;
			display: block;
			transition: all 250ms ease-in-out;
			cursor: pointer;
			height: 300px;
			margin-bottom: 10px;
			position: relative;
			z-index: 1;

			+ img {
				margin-left: 10px;
			}

			&:hover {
				transform: scale(1.15);
				z-index: 10;
			}
		}
	}
</style>