<template>
	<h1 class="page-title">{{getVolumeTitle(poem)}}</h1>
	<h2 class="page-subtitle" v-if="getPoemTitle(poem)">{{getPoemTitle(poem)}}</h2>

	<div class="poem-section">
		<div class="poem-content">
            <div v-if="poem.volume && getTranslated(poem.volume, 'title', true)" class="poem-field">
                <div class="label">{{t.fields.volume}}: </div>
                {{getTranslated(poem.volume, 'title', true)}}
            </div>

            <div v-if="poem.volume && poem.volume.rmk" class="poem-field">
                <div class="label">{{t.fields.rmk}}: </div>
                {{poem.volume.rmk}}
            </div>

            <div v-if="poem.volume && poem.volume.catalog_number" class="poem-field">
                <div class="label">{{t.fields.catalog_number}}: </div>
                {{poem.volume.catalog_number}}
            </div>

			<div v-if="getAuthor()" class="poem-field">
				<div class="label">{{t.fields.author}}: </div>
				<RouterLink :to="'/person/'+poem.author.id">{{getAuthor()}}</RouterLink>
			</div>

			<div v-if="getAddressee()" class="poem-field">
				<div class="label">{{t.fields.addressee}}: </div>
				<RouterLink :to="'/person/'+poem.volume.author.id">{{getAddressee()}}</RouterLink>
			</div>

			<div v-if="getPublisher()" class="poem-field">
				<div class="label">{{t.fields.publisher}}: </div>
				{{getPublisher()}}
			</div>

			<div v-if="getPublishingDate()" class="poem-field">
				<div class="label">{{t.fields.published_year}}: </div>
				{{getPublishingDate()}}
			</div>

			<div v-if="getPublishingLocation()" class="poem-field">
				<div class="label">{{t.fields.published_location}}: </div>
				<RouterLink :to="'/place/'+poem.volume.published_location.id">{{getPublishingLocation()}}</RouterLink>
			</div>

			<div v-if="getSupervisor()" class="poem-field">
				<div class="label">{{t.fields.supervisor}}: </div>
				<RouterLink :to="'/person/'+poem.volume.supervisor.id">{{getSupervisor()}}</RouterLink>
			</div>

			<div v-if="poem.volume.libraries && poem.volume.libraries.length" class="poem-field">
				<div class="label">{{t.fields.findings}}:</div>
                <ul>
                    <li v-for="library in poem.volume.libraries" :key="library.id">
                        <div v-if="!library.url && getTranslated(library, 'name')">{{getTranslated(library, 'name')}}</div>
                        <a v-if="library.url" :href="library.url" target="_blank">{{getTranslated(library, 'name') ? getTranslated(library, 'name') : t.fields.link }}</a>
                    </li>
                </ul>
			</div>

			<div class="poem-field" v-if="poem.verses && poem.verses.length">
				<div class="label">{{t.fields.translations}}:</div>
				<div class="accessible-languages">
					<div v-if="poem.verses[0].lines[0].line_orig" @click="showLanguage('orig')" class="language-item" :class="{'selected': poemTranslations.orig}">
						<input type="checkbox" v-model="poemTranslations.orig"/>
						<span>{{t.fields.original}}</span>
					</div>
					<div v-if="poem.verses[0].lines[0].line_norm" @click="showLanguage('norm')" class="language-item" :class="{'selected': poemTranslations.norm}">
						<input type="checkbox" v-model="poemTranslations.norm"/>
						<span>{{t.fields.normalized}}</span>
					</div>
					<div v-if="poem.verses[0].lines[0].line_en" @click="showLanguage('en')" class="language-item" :class="{'selected': poemTranslations.en}">
						<input type="checkbox" v-model="poemTranslations.en"/>
						<span>{{t.fields.en_translation}}</span>
					</div>
					<div v-if="poem.verses[0].lines[0].line_hu_raw" @click="showLanguage('hu_raw')" class="language-item" :class="{'selected': poemTranslations.hu_raw}">
						<input type="checkbox" v-model="poemTranslations.hu_raw"/>
						<span>{{t.fields.hu_raw_translation}}</span>
					</div>
					<div v-if="poem.verses[0].lines[0].line_hu_mu" @click="showLanguage('hu_mu')" class="language-item" :class="{'selected': poemTranslations.hu_mu}">
						<input type="checkbox" v-model="poemTranslations.hu_mu"/>
						<span>{{t.fields.hu_literary_translation}}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="the-poem" v-if="selectedPoems[0]">
			<div class="poem-title sor" v-if="poem['title_'+selectedPoems[0]] || (selectedPoems[1] && poem['title_'+selectedPoems[1]])">
                <p v-if="selectedPoems[0] === 'orig'">{{poem.title_orig}}</p>
                <p v-if="selectedPoems[1] && selectedPoems[1] === 'orig'">{{poem.title_orig}}</p>
                <p v-if="selectedPoems[0] === 'norm'">{{poem.title_norm}}</p>
                <p v-if="selectedPoems[1] && selectedPoems[1] === 'norm'">{{poem.title_norm}}</p>
                <p v-if="selectedPoems[0] === 'en'">{{poem.title_en}}</p>
                <p v-if="selectedPoems[1] && selectedPoems[1] === 'en'">{{poem.title_en}}</p>
                <p v-if="selectedPoems[0] === 'hu_raw'">{{poem.title_hu_raw}}</p>
                <p v-if="selectedPoems[1] && selectedPoems[1] === 'hu_raw'">{{poem.title_hu_raw}}</p>
                <p v-if="selectedPoems[0] === 'hu_mu'">{{poem.title_hu_mu}}</p>
                <p v-if="selectedPoems[1] && selectedPoems[1] === 'hu_mu'">{{poem.title_hu_mu}}</p>
			</div>

			<div v-if="getHeader()" class="poem-header sor">
				<p v-if="selectedPoems[0] === 'orig'" class="heber-font">{{getHeader().line_orig}}</p>
				<p v-if="selectedPoems[1] && selectedPoems[1] === 'orig'" class="heber-font">{{getHeader().line_orig}}</p>
				<p v-if="selectedPoems[0] === 'norm'" class="heber-font">{{getHeader().line_norm}}</p>
				<p v-if="selectedPoems[1] && selectedPoems[1] === 'norm'" class="heber-font">{{getHeader().line_norm}}</p>
				<p v-if="selectedPoems[0] === 'en'">{{getHeader().line_en}}</p>
				<p v-if="selectedPoems[1] && selectedPoems[1] === 'en'">{{getHeader().line_en}}</p>
				<p v-if="selectedPoems[0] === 'hu_raw'">{{getHeader().line_hu_raw}}</p>
				<p v-if="selectedPoems[1] && selectedPoems[1] === 'hu_raw'">{{getHeader().line_hu_raw}}</p>
				<p v-if="selectedPoems[0] === 'hu_mu'">{{getHeader().line_hu_mu}}</p>
				<p v-if="selectedPoems[1] && selectedPoems[1] === 'hu_mu'">{{getHeader().line_hu_mu}}</p>
			</div>

			<div class="the-poem-content" v-if="poem.verses">
				<div v-for="verszak in poem.verses" :key="verszak.id" class="poem-stanza">
					<div v-for="line in verszak.lines" :key="line.id" class="sor">
						<p v-if="selectedPoems[0] === 'orig'" class="heber-font">{{line.line_orig}}</p>
						<p v-if="selectedPoems[1] && selectedPoems[1] === 'orig'" class="heber-font">{{line.line_orig}}</p>
						<p v-if="selectedPoems[0] === 'norm'" class="heber-font">{{line.line_norm}}</p>
						<p v-if="selectedPoems[1] && selectedPoems[1] === 'norm'" class="heber-font">{{line.line_norm}}</p>
						<p v-if="selectedPoems[0] === 'en'">{{line.line_en}}</p>
						<p v-if="selectedPoems[1] && selectedPoems[1] === 'en'">{{line.line_en}}</p>
						<p v-if="selectedPoems[0] === 'hu_raw'">{{line.line_hu_raw}}</p>
						<p v-if="selectedPoems[1] && selectedPoems[1] === 'hu_raw'">{{line.line_hu_raw}}</p>
						<p v-if="selectedPoems[0] === 'hu_mu'">{{line.line_hu_mu}}</p>
						<p v-if="selectedPoems[1] && selectedPoems[1] === 'hu_mu'">{{line.line_hu_mu}}</p>
					</div>
				</div>
			</div>

			<div v-if="getFooter()" class="poem-footer sor">
				<p v-if="selectedPoems[0] === 'orig'" class="heber-font">{{getFooter().line_orig}}</p>
				<p v-if="selectedPoems[1] && selectedPoems[1] === 'orig'" class="heber-font">{{getFooter().line_orig}}</p>
				<p v-if="selectedPoems[0] === 'norm'" class="heber-font">{{getFooter().line_norm}}</p>
				<p v-if="selectedPoems[1] && selectedPoems[1] === 'norm'" class="heber-font">{{getFooter().line_norm}}</p>
				<p v-if="selectedPoems[0] === 'en'">{{getFooter().line_en}}</p>
				<p v-if="selectedPoems[1] && selectedPoems[1] === 'en'">{{getFooter().line_en}}</p>
				<p v-if="selectedPoems[0] === 'hu_raw'">{{getFooter().line_hu_raw}}</p>
				<p v-if="selectedPoems[1] && selectedPoems[1] === 'hu_raw'">{{getFooter().line_hu_raw}}</p>
				<p v-if="selectedPoems[0] === 'hu_mu'">{{getFooter().line_hu_mu}}</p>
				<p v-if="selectedPoems[1] && selectedPoems[1] === 'hu_mu'">{{getFooter().line_hu_mu}}</p>
			</div>
		</div>
	</div>

	<div class="poem-field" v-if="poemsInVolume.length">
		<div class="label">{{t.fields.more_volume_poems}}:</div>
		<ul>
			<li v-for="poemInVolume in poemsInVolume" :key="poemInVolume.id">
				<RouterLink :to="'/poem/'+poemInVolume.id">
					<div>
						<Popper :content="t.search.volume" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
							<img src="/volume.png" :alt="t.search.volume" class="tooltip-image"/>
						</Popper>
						<span>{{getVolumeTitle(poemInVolume)}}</span>
					</div>

					<div v-if="getPoemTitle(poemInVolume)">
						<Popper :content="t.search.poem" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
							<img src="/poem.png" :alt="t.search.poem" class="tooltip-image"/>
						</Popper>
						<span>{{getPoemTitle(poemInVolume)}}</span>
					</div>
				</RouterLink>
			</li>
		</ul>
	</div>

	<div class="poem-field" v-if="images.length">
		<div class="label">{{t.fields.images}}:</div>
		<div class="poem-images">
			<img v-for="(image, index) in images" :key="image.id" @click="openGallery(index)" :src="backendUrl()+image.url" :alt="image.alternativeText ? image.alternativeText : ''"/>
		</div>
	</div>

	<vue-easy-lightbox
		:visible="showGallery"
		:imgs="getImageUrls()"
		:index="showImage"
		@hide="hideGallery"
		:loop="true"
	></vue-easy-lightbox>
</template>

<script>
import Popper from "vue3-popper";
import VueEasyLightbox from 'vue-easy-lightbox'
import {getArrayObject, isEmptyObject, isNumber} from "@/functions";

export default {
	components: {
		Popper,
		VueEasyLightbox
	},
	inject: ['t', 'locale', 'getTranslated', 'getOriginal'],
	name: 'PoemView',
	props: {
		poems: Array,
		locations: Array,
		volumes: Array,
		people: Array,
	},
	data() {
		return {
			id: 0,
			selectionLimit: 2,
			poem: {},
			poemTranslations: {
				orig: false,
				norm: false,
				la: false,
				en: false,
				hu_raw: false,
				hu_mu: false,
			},
			selectedPoems: [],
			showImage: null,
			showGallery: false,
			images: [],
		}
	},
	created() {
		this.screenResize();
		this.getPoem();

		window.addEventListener("resize", this.screenResize);
	},
	beforeUnmount() {
		window.removeEventListener("resize", this.screenResize);
	},
	computed: {
		poemsInVolume() {
			let _this = this;
			let poems = [];

			this.poems.forEach(function (poem) {
				if(poem.volume && _this.poem.volume && (poem.volume.id === _this.poem.volume.id)) {
					if(poem.id !== _this.poem.id) {
						poems.push({
							...poem
						});
					}
				}
			});

			return poems;
		}
	},
	methods: {
		screenResize() {
			this.selectionLimit = window.innerWidth <= 800 ? 1 : 2;

			if(this.selectedPoems.length > this.selectionLimit) {
				if(this.poemTranslations.hu_mu) {
					this.showLanguage('hu_mu');
				} else if(this.poemTranslations.hu_raw) {
					this.showLanguage('hu_raw');
				} else if(this.poemTranslations.en) {
					this.showLanguage('en');
				} else if(this.poemTranslations.norm) {
					this.showLanguage('norm');
				}
			}
		},
		backendUrl() {
			return process.env.VUE_APP_BACKEND_URL;
		},
		openGallery(index) {
			this.showGallery = true;
			this.showImage = index;
		},
		hideGallery() {
			this.showGallery = false;
		},
		getPoem() {
			if((this.$route.params && this.$route.params.id && isNumber(this.$route.params.id))) {
				this.id = Number(this.$route.params.id);
				this.poem = {...getArrayObject(this.poems, 'id', this.id)};
				this.selectionLimit = 2;
				this.selectedPoems = [];
				this.poemTranslations = {
					orig: false,
					norm: false,
					la: false,
					en: false,
					hu_raw: false,
					hu_mu: false,
				};

				if(this.poem.images && this.poem.images.length) {
					this.images = [...this.poem.images];
				}

				if(this.poem.volume.images && this.poem.volume.images.length) {
					this.images = [...this.images, ...this.poem.volume.images];
				}

                if(this.poem.verses && this.poem.verses.length) {
                    if(this.poem.verses[0].lines[0].line_orig) {
                        this.showLanguage('orig');
                    } else if(this.poem.verses[0].lines[0].line_norm) {
                        this.showLanguage('norm');
                    }

                    this.screenResize();

                    if(this.selectionLimit === 2 || (!this.poemTranslations.orig && !this.poemTranslations.norm)) {
                        if(this.locale === 'hu') {
                            if(this.poem.verses[0].lines[0].line_hu_mu) {
                                this.showLanguage('hu_mu');
                            } else if(this.poem.verses[0].lines[0].line_hu_raw) {
                                this.showLanguage('hu_raw');
                            } else if(this.poem.verses[0].lines[0].line_en) {
                                this.showLanguage('en');
                            } else if(this.poem.verses[0].lines[0].line_orig && this.poem.verses[0].lines[0].line_norm) {
                                this.showLanguage('norm');
                            }
                        } else if(this.locale === 'en') {
                            if(this.poem.verses[0].lines[0].line_en) {
                                this.showLanguage('en');
                            } else if(this.poem.verses[0].lines[0].line_hu_mu) {
                                this.showLanguage('hu_mu');
                            } else if(this.poem.verses[0].lines[0].line_hu_raw) {
                                this.showLanguage('hu_raw');
                            } else if(this.poem.verses[0].lines[0].line_orig && this.poem.verses[0].lines[0].line_norm) {
                                this.showLanguage('norm');
                            }
                        }
                    }
				}
			}

			if(isEmptyObject(this.poem)) {
				this.$router.push('/404');
			}
		},
		getImageUrls() {
			let imageUrls = [];
			let _this = this;

			this.images.forEach(function (image) {
				imageUrls.push(_this.backendUrl() + image.url);
			});

			return imageUrls;
		},
		getPoemTitle(poem) {
			let title = "";

			if(this.getOriginal(poem, 'title')) {
				title = this.getOriginal(poem, 'title');
			}

			if(!title && poem.header) {
				title = this.getOriginal(poem.header, 'line');
			}

			return title;
		},
		getVolumeTitle(poem) {
			if(poem.volume) {
				return this.getOriginal(poem.volume, 'title');
			}

			return '';
		},
		getAuthor() {
			if(this.poem.author) {
				return this.getTranslated(this.poem.author, 'name');
			}

			return '';
		},
		getAddressee() {
			if(this.poem.volume && this.poem.volume.author) {
				return this.getTranslated(this.poem.volume.author, 'name');
			}

			return '';
		},
		getPublisher() {
			if(this.poem.volume) {
				return this.getTranslated(this.poem.volume, 'publisher');
			}

			return '';
		},
		getPublishingDate() {
			if(this.poem.volume) {
				return this.getTranslated(this.poem.volume, 'published_year');
			}

			return '';
		},
		getPublishingLocation() {
			if(this.poem.volume && this.poem.volume.published_location) {
				return this.getTranslated(this.poem.volume.published_location, 'name', true);
			}

			return '';
		},
		getSupervisor() {
			if(this.poem.volume && this.poem.volume.supervisor) {
				return this.getTranslated(this.poem.volume.supervisor, 'name');
			}

			return '';
		},
		getHeader() {
			if(this.poem.header && this.poem.header.id) {
				return this.poem.header;
			}

			return false;
		},
		getFooter() {
			if(this.poem.footer && this.poem.footer.id) {
				return this.poem.footer;
			}

			return false;
		},
		showLanguage(lang) {
			let _this = this;

			if(!this.poemTranslations[lang]) {
				let selectedCounter = 0;
				let switchOff = '';

				Object.entries(_this.poemTranslations).forEach(entry => {
					const [offLang, value] = entry;

					if(value) {
						switchOff = offLang;
						selectedCounter++;
					}
				});

				if(selectedCounter === this.selectionLimit) {
					this.showLanguage(switchOff);
				}

				this.poemTranslations[lang] = true;

				//Add Language
				this.selectedPoems.push(lang);
			} else {
				this.poemTranslations[lang] = false;

				//Remove Language
				let selectedPoems = [];

				if(this.selectedPoems.length === 2) {
					Object.entries(_this.poemTranslations).forEach(entry => {
						const [activeLang, value] = entry;

						if(value) {
							selectedPoems.push(activeLang);
						}
					});
				}

				this.selectedPoems = selectedPoems;
			}
		},
	},
    watch: {
        '$route.params.id': function () {
            window.scrollTo(0, 0);
            this.getPoem();
        }
    }
};
</script>

<style scoped lang="scss">
	.poem-field {
		font-size: 18px;

		.label {
			color: #5b5b5b;
			font-size: 14px;
			display: block;
			width: 100%;
			line-height: 1;
		}

		+ .poem-field {
			margin-top: 15px;
		}
	}

	a {
		color: #804010;
		transition: all 250ms ease-in-out;

		&:hover {
			color: #cb946c;
		}
	}

	.poem-section {
		display: flex;
		flex-flow: column;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: nowrap;
		width: 100%;

		@media only screen and (max-width: 1200px) {
			flex-flow: column;
			flex-direction: column;
		}

		.accessible-languages {
			display: flex;
			flex-flow: column;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap: nowrap;
			margin-top: 5px;
			width: 320px;
			flex-grow: 0;
			flex-shrink: 0;

			.language-item {
				display: flex;
				flex-flow: row;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				flex-wrap: nowrap;
				width: auto;
				transition: all 250ms ease-in-out;
				cursor: pointer;
				color: #804010;

				&:hover {
					color: #cb946c;
				}

				&.selected {
					color: #000000;
				}

				+ .language-item {
					margin-top: 10px;
				}

				input {
					margin-right: 8px;
					font-size: 18px;
					width: 18px;
					height: 18px;
					pointer-events: none;
				}
			}
		}

		.poem-content {
			flex-grow: 1;
			display: flex;
			flex-flow: column;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap: nowrap;
			width: 100%;
			margin-bottom: 20px;

			@media only screen and (max-width: 1200px) {
				max-width: 100%;
			}
		}

		.the-poem {
			display: flex;
			flex-flow: column;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap: nowrap;
			flex-grow: 1;
			flex-shrink: 1;
			width: 100%;
			font-size: 16px;
			line-height: 20px;

			.sor {
				display: flex;
				flex-flow: row;
				flex-direction: row;
				align-items: flex-start;
				justify-content: flex-start;
				flex-wrap: nowrap;
				width: 100%;
			}

			.the-poem-content {
				width: 100%;
			}

			.poem-header, .poem-stanza, .poem-footer, .poem-title {
				margin-bottom: 20px;
				width: 100%;

				p {
					width: 50%;
					max-width: 50%;
					flex-shrink: 0;
					flex-grow: 0;
					margin: 0 0 5px 0;

					&:first-child {
						padding-right: 25px;

						@media only screen and (max-width: 800px) {
							padding-right: 0;
							width: 100%;
							max-width: 100%;
						}
					}

					&:nth-child(2) {
						padding-left: 25px;

						@media only screen and (max-width: 800px) {
							display: none;
						}
					}
				}
			}
		}
	}

	.poem-images {
		display: flex;
		flex-flow: row;
		flex-direction: row;
		align-items: stretch;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 5px;

		img {
			width: auto;
			display: block;
			transition: all 250ms ease-in-out;
			cursor: pointer;
			height: 300px;
			margin-bottom: 10px;
			position: relative;
			z-index: 1;

			+ img {
				margin-left: 10px;
			}

			&:hover {
				transform: scale(1.15);
				z-index: 10;
			}
		}
	}
</style>