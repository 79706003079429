import {createRouter, createWebHistory} from 'vue-router'
import SearchView from '../views/SearchView.vue'
import PoemView from '../views/PoemView.vue'
import PersonView from '../views/PersonView.vue'
import PlaceView from '../views/PlaceView.vue'
import PageView from '../views/PageView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import { hu } from '@/i18n/hu';

window.t = hu;

const routes = [
    {
        path: '/',
        name: 'search',
        component: SearchView
    },
    {
        path: '/poem/:id',
        name: 'poem',
        component: PoemView
    },
    {
        path: '/person/:id',
        name: 'person',
        component: PersonView
    },
    {
        path: '/place/:id',
        name: 'place',
        component: PlaceView
    },
    {
        path: '/404',
        name: 'not_found',
        component: NotFoundView
    },
    {
        path: '/page/:slug',
        name: 'page',
        component: PageView
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = window.t.meta.title + (window.t.menu[to.name] ? ' | ' + window.t.menu[to.name] : '');
    next();
});

export default router
