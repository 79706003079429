<template>
	<div class="dropdown-item">
        <Popper :content="t.search[item.type]" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
			<img class="icon" :src="'/'+item.type+'.png'" :alt="t.search[item.type]"/>
		</Popper>
		<div class="text">
			{{ item.text }}
		</div>
	</div>
</template>

<script>
import Popper from "vue3-popper";

export default {
	inject: ['t','getTranslated'],
	name: 'DropdownItem',
	props: {
		item: Object,
	},
    components: {
        Popper
    },
};
</script>

<style scoped lang="scss">
	.dropdown-item {
		background-color: transparent;
		text-align: left;
		padding: 0 8px;
		line-height: 18px;
		font-size: 16px;
		color: #000000;
		display: flex;
		flex-flow: row;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: nowrap;
		width: 100%;
		cursor: pointer;
		transition: all 250ms ease-in-out;
		border-bottom: 1px solid #525252;

		&:hover {
			background-color: #ebc4a7;
		}

		.icon {
			min-width: 28px;
			width: 28px;
			height: 28px;
			margin-right: 8px;
			flex-grow: 0;
			flex-shrink: 0;
			margin-top: 4px;

			&[src="/location.png"] {
				margin-top: 3px;
			}
		}

		.text {
			flex-grow: 1;
			flex-shrink: 1;
			width: auto;
			padding-top: 9px;
			padding-bottom: 9px;
			line-height: 18px;
		}
	}
</style>