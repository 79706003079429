<template>
	<Header :pages="pages"/>
	<section class="container page">
		<RouterView v-if="loaded" :locations="locations" :volumes="volumes" :people="people" :poems="poems" :pages="pages" :homeContent="homeContent" :reset="resetSearchKey"/>
	</section>
	<Footer/>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import {createSlug, fetch} from "@/functions";

import { hu } from '@/i18n/hu';
import { en } from '@/i18n/en';
import { computed } from "vue";

export default {
	name: 'App',
	components: {
		Header,
		Footer,
	},
	data() {
		return {
			actualLang: 'hu',
			hu: hu,
			en: en,
			locations: [],
			volumes: [],
			people: [],
			poems: [],
			pages: [],
            homeContent: {},
			loading: {
				locations: false,
				volumes: false,
				people: false,
				poems: false,
				pages: false,
			},
			allResult: false,
			selected: [],
            resetSearchKey: 0,
		}
	},
	created() {
		this.refreshData();
	},
	methods: {
		setLocations(response) {
			this.locations = this.structureDeep(response);
			//console.log(this.locations);
			this.loading.locations = true;
		},
		setVolumes(response) {
			this.volumes = this.structureDeep(response);
			//console.log(this.volumes);
			this.loading.volumes = true;
		},
		setPeople(response) {
			this.people = this.structureDeep(response);
			//console.log(this.people);
			this.loading.people = true;
		},
		setPoems(response) {
			this.poems = this.structureDeep(response);
			//console.log(this.poems);
			this.loading.poems = true;
		},
		setPages(response) {
			let _this = this;
			let pages = [];

            this.structureDeep(response).forEach(function (page) {
                if(page.title_hu && page.title_en) {
                    if('home' === createSlug(page.title_en)) {
                        _this.homeContent = {...page};
					} else {
                        pages.push({
                            ...page,
                            slug: createSlug(page.title_en)
                        });
					}
				}
            });

            this.pages = pages;
            //console.log(this.pages);
			this.loading.pages = true;
		},
		refreshData() {
			this.loading = {
				locations: false,
				volumes: false,
				people: false,
				poems: false,
				pages: false,
			};

			fetch('locations', this.setLocations);
			fetch('volumes', this.setVolumes);
			fetch('people', this.setPeople);
			fetch('poems', this.setPoems);
			fetch('pages', this.setPages);
		},
		getOppositeLanguage(languageCode) {
			return languageCode === 'hu' ? 'en' : 'hu';
		},
        structureDeep(item) {
            let _this = this;
            let deepStructure;

            if(item && item.data && typeof item === 'object' && !Array.isArray(item) && (Object.keys(item).length === 1)) {
                item = item.data;
            }

            if(item && typeof item === 'object') {
                if(Array.isArray(item)) {
                    deepStructure = [];

                    item.forEach(function (subitem) {
                        deepStructure.push(_this.structureDeep(subitem));
                    });
                } else {
                    deepStructure = {};
                    let structuredObject = {};

                    if(item.attributes && item.id && (Object.keys(item).length === 2)) {
                        structuredObject = {
                            id: item.id,
                            ...item.attributes
                        };
                    } else {
                        structuredObject = {...item};
                    }

                    Object.keys(structuredObject).forEach(key => {
                        if(typeof structuredObject[key] === 'object' || Array.isArray(structuredObject[key])) {
                            deepStructure[key] = _this.structureDeep(structuredObject[key]);
                        } else {
                            deepStructure[key] = structuredObject[key];
                        }
                    });
                }
            } else {
                deepStructure = item;
            }

            return deepStructure;
        }
	},
	computed: {
		loaded() {
			return this.loading.locations && this.loading.volumes && this.loading.people && this.loading.poems && this.loading.pages;
		},
	},
	provide() {
		return {
			locale: computed(() => this.actualLang),
			t: computed(() => this[this.actualLang]),
            resetSearch: () => {
                this.resetSearchKey++;
			},
			languageChange: (lang) => {
				window.t = this[lang];
				this.actualLang = lang;
				document.title = window.t.meta.title + ' | ' + window.t.menu[this.$route.name];
			},
            getDeepStructure: (item) => {
                return this.structureDeep(item);
            },
            getSelected: computed(() => this.selected),
            setSelected: (items) => {
                return this.selected = [...items];
            },
            getAllResult: computed(() => this.allResult),
            setAllResult: (status) => {
                return this.allResult = status
            },
			refreshAll: () => {
				this.refreshData();
			},
			getOriginal: (obj, name) => {
				let translated = '';

				switch (true) {
					case !!obj[name]:
						translated = obj[name];
						break;
					case !!obj[name+'_orig']:
						translated = obj[name+'_orig'];
						break;
					case !!obj[name+'_norm']:
						translated = obj[name+'_norm'];
						break;
					case this.actualLang === 'en' && !!obj[name+'_en']:
						translated = obj[name+'_en'];
						break;
					case this.actualLang === 'hu' && !!obj[name+'_hu_mu']:
						translated = obj[name+'_hu_mu'];
						break;
					case this.actualLang === 'hu' && !!obj[name+'_hu_raw']:
						translated = obj[name+'_hu_raw'];
						break;
					default:
				}

				return translated;
			},
			getTranslated: (obj, name, skipOpposite) => {
				let translated = '';

				switch (true) {
					case !!obj[name+'_'+this.actualLang]:
						translated = obj[name+'_'+this.actualLang];
						break;
					case (!!obj[name+'_'+this.getOppositeLanguage(this.actualLang)] && !skipOpposite):
						translated = obj[name+'_'+this.getOppositeLanguage(this.actualLang)];
						break;
					case !!obj[name]:
						translated = obj[name];
						break;
					default:
				}

				return translated;
			}
		}
	},
};
</script>

<style scoped lang="scss">
	.page {
		width: 100%;
		padding-top: 40px;
		padding-bottom: 40px;
		min-height: calc(100vh - 60px - 160px);

		@media screen and (max-width: 1430px) {
			padding-left: 15px;
			padding-right: 15px;
		}

        @media screen and (max-width: 600px) {
            min-height: calc(100vh - 60px - 130px);
        }
	}
</style>

<style lang="scss">
	@media only screen and (max-width: 460px) {
		@-o-viewport {
			width: 460px;
		}
		@viewport {
			width: 460px;
		}
	}

	html, body {
		display: block;
		width: 100%;
		margin: 0;
		padding: 0;
		font-family: 'Poppins', Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		font-size: 16px;
		line-height: 1.44;
		letter-spacing: normal;
		text-rendering: optimizeLegibility;
		direction: ltr;
		color: #000000;
		background-color: transparent;
		position: relative;
		--popper-theme-background-color: #ffffff;
		--popper-theme-background-color-hover: #ffffff;
		--popper-theme-text-color: #000000;
		--popper-theme-border-width: 1px;
		--popper-theme-border-style: solid;
		--popper-theme-border-color: #ffffff;
		--popper-theme-border-radius: 4px;
		--popper-theme-padding: 6px 12px;
		--popper-theme-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);

		.popper {
			margin-bottom: 8px !important;
			font-size: 16px !important;
			font-weight: normal !important;

			#arrow:before {
				margin-left: -5px;
			}
		}

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			min-height: 100vh;
			background-image: url("./assets/background.jpg");
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			content: "";
			display: block;
			z-index: 0;
			opacity: 0.5;
		}
	}

	#app {
		position: relative;
	}

	* {
		font-family: 'Poppins', Avenir, Helvetica, Arial, sans-serif;
		margin: 0;
		padding: 0;
		font-kerning: normal;
		text-rendering: optimizeLegibility;
		box-sizing: border-box;

		&:focus, &:active {
			outline: none !important;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		font-stretch: normal;
		margin-top: 0;
		margin-bottom: 0;
		display: block;
	}

	a {
		cursor: pointer;
		outline: none;
		text-decoration: none;

		&:focus, &:hover, &:active, &:visited, &:link {
			cursor: pointer;
			outline: none;
			text-decoration: none;
		}

		&.disabled, &[disabled="true"], &[disabled="disabled"], &[disabled] {
			cursor: not-allowed;
			pointer-events: none;
			background-color: #525252;
		}
	}

	ul {
		width: 100%;
		display: block;
		padding-left: 30px;

		li {
			width: 100%;
			display: list-item;

			&::marker {
				color: #5b5b5b;
			}

			+ li {
				margin-top: 10px;
			}

			a {
				display: block;
				width: fit-content;

				div {
					width: fit-content;
				}
			}
		}
	}

	label {
		font-weight: normal;
	}

	img {
		display: block;
		width: 100%;
		max-width: 100%;
		height: auto;
	}

	section {
		width: 100%;
	}

	button, .button {
		background-color: transparent;
		color: #000000;
		border: 0;
		border-radius: 0;
		outline: none;
		cursor: pointer;
		padding: 0;

		&:focus, &:hover, &:active, &:visited, &:link {
			cursor: pointer;
			outline: none;
		}

		&.disabled, &[disabled="true"], &[disabled="disabled"], &[disabled] {
			cursor: not-allowed;
			background-color: #525252;
			pointer-events: none;
		}
	}

	.select, .input {
		border-radius: 4px;
		border: 1px solid #525252;
		background-color: #ffffff;
		display: block;
		width: 100%;
		font-size: 16px;
		color: #000000;
		height: 40px;
		line-height: 32px;
		padding: 4px 14px;
		box-shadow: none !important;
		outline: none !important;
		transition: all 350ms ease;

		&:active, &:hover {
			border-color: #000000;
		}

		&:active, &:hover, &:focus {
			outline: none !important;
			box-shadow: none !important;
		}
	}

	.input {
		&::placeholder {
			color: #000000;
			opacity: 0.6;
		}

		&:-ms-input-placeholder {
			color: #000000;
			opacity: 0.6;
		}

		&::-ms-input-placeholder {
			color: #000000;
			opacity: 0.6;
		}
	}

	.container {
		width: 100%;
		max-width: 1400px;
		margin-left: auto;
		margin-right: auto;
	}

	.tooltip-image {
		display: inline-flex;
		width: 32px;
		height: 32px;
		margin-right: 5px;
		margin-bottom: -7px;

		@media screen and (max-width: 1430px) {
			width: 26px;
			height: 26px;
			margin-right: 5px;
			margin-bottom: -5px;
		}
	}

	.page-title {
		display: block;
		width: 100%;
		font-size: 38px;
		text-align: center;
		margin-bottom: 40px;

		@media screen and (max-width: 700px) {
			font-size: 32px;
		}

		@media screen and (max-width: 500px) {
			font-size: 28px;
		}
	}

	.page-subtitle {
		display: block;
		width: 100%;
		text-align: center;
		font-size: 24px;
		margin-top: -15px;
		margin-bottom: 30px;

		@media screen and (max-width: 560px) {
			font-size: 20px;
		}

		span:not(:last-child):after {
			content: ", ";
			display: inline-block;
			margin-right: 10px;
		}
	}

	.site-content {
		font-size: 20px;

		@media screen and (max-width: 600px) {
			font-size: 18px;
		}
	}

	.vel-modal {
		background-color: rgba(0,0,0,0.7);

		img {
			width: unset;
		}
	}

	.vel-img-title {
		opacity: 1;
		font-size: 18px;
	}

	.vel-btns-wrapper .btn__close, .vel-btns-wrapper .btn__next, .vel-btns-wrapper .btn__prev {
		opacity: 0.75;
	}

    .site-content {
		width: 100%;

        p:empty {
			margin: 0;
            padding-bottom: 20px;
        }

        a {
            color: #804010;
            white-space: nowrap;
            transition: all 250ms ease-in-out;

            &:hover {
                color: #cb946c;
            }
        }

		img {
			max-width: 800px;
			margin: 0 auto;
		}
	}

	.heber-font {
        font-family: 'Times New Roman', sans-serif, sans, serif;
		font-size: 20px;
	}
</style>