<template>
	<footer class="footer">
		<section class="container">
			<h5>Carmina gratulatoria hebraica</h5>
			<RouterLink to="/page/research">
				<img class="palyazat-logo" :src="'/NKFIA_infoblokk_kerettel_projekt_fekvo_'+locale+'.png'" :alt="t.page.project_title"/>
			</RouterLink>
        </section>
	</footer>
</template>

<script>
export default {
	inject: ['t', 'locale'],
	name: 'Footer',
};
</script>

<style scoped lang="scss">
	.footer {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 80px, transparent 50%);

		.container {
            display: flex;
            flex-flow: row;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            flex-wrap: nowrap;
		}

		h5 {
            margin-left: 0;
            margin-right: auto;
			font-size: 20px;
			color: #000000;
			line-height: 1.3;
			height: 80px;
            display: inline-flex;
            flex-flow: row;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: nowrap;

            @media screen and (max-width: 1430px) {
                padding-left: 15px;
            }

            @media screen and (max-width: 700px) {
                font-size: 18px;
            }
		}

		a {
            @media screen and (min-width: 1400px) {
                transform: translateX(calc(50vw - 700px));
            }

			margin-left: auto;
			margin-right: 0;
			flex-shrink: 0;
			flex-grow: 0;

			img {
				height: 160px;
				width: auto;

                @media screen and (max-width: 600px) {
					height: 130px;
					width: auto;
					max-width: 100%;
                }
			}
		}
	}
</style>