<template>
	<header class="header">
		<section class="container">
			<RouterLink to="/" @click="goHome">
				<h2 class="site-title">{{ t.meta.title }}</h2>
			</RouterLink>
			<nav class="menu" :class="{opened: menu}">
				<RouterLink to="/" @click="closeMenu">{{ t.menu.search }}</RouterLink>
				<RouterLink v-for="page in pages" :key="page.id" :to="'/page/'+page.slug" @click="closeMenu">{{ getTranslated(page, 'title', true) }}</RouterLink>
			</nav>
			<nav class="languages">
				<button @click="changeLang('hu')" :class="{active: locale === 'hu'}">
					<img src="/hu.svg" :alt="t.language.hu"/>
				</button>
				<button @click="changeLang('en')" :class="{active: locale === 'en'}">
					<img src="/gb.svg" :alt="t.language.en"/>
				</button>
			</nav>
			<button @click="toggleMenu" class="responsive-menu">
				<img :src="'/'+(menu?'close':'menu')+'.svg'" :alt="t.result.close"/>
			</button>
		</section>
	</header>
</template>

<script>
export default {
	inject: ['t', 'locale', 'languageChange', 'getTranslated', 'resetSearch'],
	name: 'Header',
    props: {
        pages: Array,
    },
	data() {
		return {
			menu: false,
		}
	},
	methods: {
		changeLang(lang) {
			this.languageChange(lang);
		},
		toggleMenu() {
			this.menu = !this.menu;
		},
		closeMenu() {
			this.menu = false;
		},
        goHome() {
			this.menu = false;
            this.resetSearch();
		}
	},
};
</script>

<style scoped lang="scss">
	.header {
		width: 100%;
		background-color: rgba(0, 0, 0, 0.2);

		.container {
			display: flex;
			flex-flow: row;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			flex-wrap: nowrap;
			height: 60px;

			@media screen and (max-width: 1430px) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		.site-title {
			font-size: 24px;
			line-height: 1.2;
			font-weight: bold;
			margin-left: 0;
			margin-right: auto;
			justify-self: flex-start;
			text-transform: uppercase;
			color: #000000;

			@media screen and (max-width: 1200px) {
				font-size: 22px;
			}

			@media screen and (max-width: 560px) {
				font-size: 20px;
			}
		}

		nav {
			padding: 0;
			margin: 0;
		}

		.menu, .languages {
			display: inline-flex;
			flex-flow: row;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: nowrap;
			width: auto;
		}

		.menu {
			@media screen and (min-width: 1001px) {
				margin-left: auto;
				margin-right: auto;

				a {
					font-size: 18px;
					color: #000000;
					text-transform: uppercase;
					font-weight: bold;
					padding: 6px 14px;
					border: 1px solid transparent;
					border-radius: 4px;
					transition: all 250ms ease-in-out;
					background-color: transparent;

					&:hover {
						border: 1px solid #804010;
						color: #804010;
					}

					&.router-link-active {
						color: #804010;
					}

					+ a {
						margin-left: 10px;

						@media screen and (max-width: 1100px) {
							margin-left: 5px;
						}
					}
				}
			}

			@media screen and (max-width: 1000px) {
				flex-flow: column;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: calc(100% - 60px);
				position: fixed;
				top: 60px;
				left: 0;
				background-color: rgba(246, 231, 189, 0.95);
				transition: transform 250ms ease-in-out;
				transform: translateX(100%);
				z-index: 5;
				padding-bottom: 60px;

				&.opened {
					transform: translateX(0);
				}

				a {
					font-size: 22px;
					color: #000000;
					text-transform: uppercase;
					font-weight: bold;
					margin-top: 20px;
					margin-bottom: 20px;

					&.router-link-active {
						color: #804010;
					}
				}
			}
		}

		.languages {
			margin-left: 0;
			margin-right: 0;
			justify-self: flex-end;

			@media screen and (max-width: 1000px) {
				margin-left: auto;
			}

			button {
				background: none;
				border: none;
				padding: 0;
				margin: 0;
				position: relative;
				transition: all 250ms ease-in-out;
				transform-origin: center center;

				&:hover {
					transform: scale(1.15);
				}

				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					background-color: rgba(0, 0, 0, 0);
					transition: all 250ms ease-in-out;
				}

				&:not(.active):after {
					background-color: rgba(0, 0, 0, 0.2);
				}

				img {
					width: 40px;
					height: auto;
				}

				+ button {
					margin-left: 15px;
				}
			}
		}

		.responsive-menu {
			display: none;

			img {
				width: 44px;
				height: auto;

				@media screen and (max-width: 480px) {
					width: 34px;
				}
			}

			@media screen and (max-width: 1000px) {
				display: block;
				margin-left: 30px;
				margin-top: -5px;
				margin-bottom: -5px;
			}

			@media screen and (max-width: 480px) {
				margin-left: 15px;
			}
		}
	}
</style>