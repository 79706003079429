<template>
	<h1 class="page-title">{{ t.menu.error }} 404</h1>
	<h2 class="page-subtitle">{{ t.menu.not_found }}</h2>
</template>

<script>
export default {
	inject: ['t', 'locale'],
	name: 'SearchView',
};
</script>