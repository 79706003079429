<template>
	<div class="map">
		<l-map
			v-model:zoom="zoom"
			:center="[50, 15]"
		>
			<l-tile-layer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			></l-tile-layer>

			<l-control-layers/>

			<l-marker v-for="poi in pois" :key="poi.id" :lat-lng="[poi.lat, poi.lon]" @click="navigate(poi.id)">
				<l-tooltip>
					<div class="place-name">{{ getTranslated(poi, 'name', true) }}</div>
					<img v-if="poi.image" class="place-image" :src="poi.image" :alt="poi.name"/>
				</l-tooltip>
			</l-marker>
		</l-map>
	</div>
</template>

<script>
import {
	LMap,
	LTileLayer,
	LMarker,
	LControlLayers,
	LTooltip,
} from "@vue-leaflet/vue-leaflet";

import "leaflet/dist/leaflet.css";
import {findArrayObject} from "@/functions";

export default {
	inject: ['t', 'locale', 'getTranslated'],
	name: 'Map',
	components: {
		LMap,
		LTileLayer,
		LMarker,
		LControlLayers,
		LTooltip,
	},
	props: {
		poems: Array,
	},
	data() {
		return {
			zoom: 5,
		};
	},
	methods: {
		navigate(id) {
			this.$router.push('/place/'+id);
		}
	},
	computed: {
		pois() {
			let pois = [];

			this.poems.forEach(function (poem) {
				if(poem.volume && poem.volume.published_location) {
					let location = poem.volume.published_location;

					if(!findArrayObject(pois, 'id', location.id)) {
						pois.push({
							id: location.id,
							name_hu: location.name_hu,
							name_en: location.name_en,
							name_la: location.name_la,
							lat: Number(location.latitude),
							lon: Number(location.longitude),
							image: (location.images && location.images.length) ? process.env.VUE_APP_BACKEND_URL+location.images[0].url : ''
						});
					}
				}
			});

			return pois;
		},
	},
};
</script>

<style lang="scss">
	.leaflet-top.leaflet-right,
	.leaflet-bottom.leaflet-right {
		display: none;
	}

	.leaflet-tooltip {
		opacity: 1 !important;
	}
</style>

<style scoped lang="scss">
	.map {
		width: 100%;
		height: 600px;
		position: relative;
		z-index: 1;

		@media screen and (max-width: 560px) {
			height: 500px;
		}

		.place-name {
			font-size: 18px;
			display: block;
			font-weight: bold;
			text-transform: capitalize;
		}

		.place-image {
			display: block;
			min-width: 200px;
			width: 200px;
			height: auto;
		}
   }
</style>
