<template>
	<h1 class="page-title">{{ getTranslated(page, 'title', true) }}</h1>

	<div class="site-content" v-html="getTranslated(page, 'text', true)"/>
</template>

<script>
import {getArrayObject, findArrayObject, isEmptyObject} from "@/functions";

export default {
    inject: ['t', 'getTranslated'],
	name: 'PageView',
    props: {
        pages: Array,
    },
    data() {
        return {
            slug: {},
            page: {},
        }
    },
    created() {
		this.getPage();
    },
    methods: {
        backendUrl() {
            return process.env.VUE_APP_BACKEND_URL;
        },
        getPage() {
            if (this.$route.params && this.$route.params.slug) {
                this.slug = this.$route.params.slug;

                if (findArrayObject(this.pages, 'slug', this.slug)) {
                    let page = {...getArrayObject(this.pages, 'slug', this.slug)};

                    page.text_hu = page.text_hu.replaceAll('/uploads/', this.backendUrl()+'/uploads/');
                    page.text_en = page.text_en.replaceAll('/uploads/', this.backendUrl()+'/uploads/');

                    document.title = window.t.meta.title + ' | ' + this.getTranslated(page, 'title', true);

                    this.page = page;
                }
            }

            if (isEmptyObject(this.page)) {
                this.$router.push('/404');
            }
        }
    },
    watch: {
        '$route.params.slug': function () {
            window.scrollTo(0, 0);
            this.getPage();
        }
    },
};
</script>