export const hu = {
    lorem: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    language: {
        hu: 'Magyar',
        en: 'Angol',
    },
    meta: {
        title: 'Héber köszöntőversek',
    },
    menu: {
        search: 'Keresés',
        poem: 'Vers',
        person: 'Személy',
        place: 'Helység',
        not_found: 'A keresett oldal nem található',
        error: 'Hiba',
        page: ''
    },
    search: {
        person: 'Személy',
        people: 'Személy',
        persons: 'Személyek',
        poem: 'Vers',
        poems: 'Versek',
        location: 'Helység',
        locations: 'Helységek',
        volume: 'Kötet',
        volumes: 'Kötetek',
        search: 'Keresés',
        placeholder: 'Kezdjen el gépelni...',
        noResult: 'Nincs találat',
        allResult: 'Összes vers'
    },
    result: {
        more: 'Bővebben',
        close: 'Bezárás',
        delete: 'Törlés',
        filteredBy: 'Szűrés:',
        noResult: 'Sajnos nem található a keresett tartalom.',
    },
    page: {
        project_title: 'Magyar peregrinusok héber köszöntőversei a 17. században',
    },
    fields: {
        author: 'Szerző',
        addressee: 'Címzett',
        publisher: 'Kiadó / nyomda',
        published_year: 'Publikálási időpont',
        published_location: 'Publikálás helyszíne',
        supervisor: 'Elnöke',
        findings: 'Lelőhelyek',
        original: 'eredeti',
        normalized: 'normalizált',
        en_translation: 'Angol fordítás',
        hu_raw_translation: 'Magyar nyersfordítás',
        hu_literary_translation: 'Magyar műfordítás',
        translations: 'Elérhető fordítások',
        birth: 'Született',
        died: 'Elhunyt',
        birth_location: 'Születésének helye',
        study_locations: 'Tanulmányainak helyszíne',
        literatures: 'Hivatkozások',
        namespace: 'Névtér',
        namespace_url: 'Névtér elérhetőség',
        bio: 'Életrajz',
        link: 'Hivatkozás',
        more_volume_poems: 'Kötetben szereplő további versek',
        poems_write_by_me: 'Költő által írt versek',
        poems_write_to_me: 'Költőhöz címzett versek',
        poems_supervised: 'Versek, melyek Elnöke volt',
        connected_persons: 'Kapcsolódó személyek',
        connected_poems: 'Kapcsolódó versek',
        images: 'Képek',
        volume: 'Kötet',
        catalog_number: 'Katalógusszám',
        rmk: 'RMK / RMNY',
    }
}