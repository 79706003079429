<template>
	<h1 class="page-title">{{ getTranslated(person, 'name') }}</h1>
	<h2 class="page-subtitle" v-if="names.length">
		(<span v-for="(name, index) in names" :key="index">{{name}}</span>)
	</h2>

	<div v-if="getBirth()" class="poem-field">
		<div class="label">{{t.fields.birth}}: </div>
		{{getBirth()}}
	</div>

	<div v-if="getDied()" class="poem-field">
		<div class="label">{{t.fields.died}}: </div>
		{{getDied()}}
	</div>

	<div v-if="getBirthLocation()" class="poem-field">
		<div class="label">{{t.fields.birth_location}}: </div>
		<RouterLink :to="'/place/'+person.birth_location.id">{{getBirthLocation()}}</RouterLink>
	</div>

	<div v-if="getStudyLocations().length" class="poem-field">
		<div class="label">{{t.fields.study_locations}}: </div>
		<ul v-if="getStudyLocations().length > 1">
			<li v-for="birth_location in getStudyLocations()" :key="birth_location.id">
				<RouterLink v-if="getTranslated(birth_location, 'name', true)" :to="'/place/'+birth_location.id">{{getTranslated(birth_location, 'name', true)}}</RouterLink>
			</li>
		</ul>
		<div v-if="getStudyLocations().length === 1">
			<RouterLink v-if="getTranslated(getStudyLocations()[0], 'name', true)" :to="'/place/'+getStudyLocations()[0].id">{{getTranslated(getStudyLocations()[0], 'name', true)}}</RouterLink>
		</div>
	</div>

	<div v-if="getLiteratures().length" class="poem-field">
		<div class="label">{{t.fields.literatures}}: </div>
		<ul v-if="getLiteratures().length > 1">
			<li v-for="literature in getLiteratures()" :key="literature.id">
                <span v-if="!getTranslated(literature, 'url', true) && getTranslated(literature, 'title', true)">{{getTranslated(literature, 'title', true)}}</span>
				<a v-if="getTranslated(literature, 'url', true)" :href="getTranslated(literature, 'url', true)" target="_blank">{{getTranslated(literature, 'title', true) ? getTranslated(literature, 'title', true) : t.fields.link}}</a>
			</li>
		</ul>
		<div v-if="getLiteratures().length === 1">
            <span v-if="!getTranslated(getLiteratures()[0], 'url', true) && getTranslated(getLiteratures()[0], 'title', true)">{{getTranslated(getLiteratures()[0], 'title', true)}}</span>
			<a v-if="getTranslated(getLiteratures()[0], 'url', true)" :href="getTranslated(getLiteratures()[0], 'url', true)" target="_blank">{{getTranslated(getLiteratures()[0], 'title', true) ? getTranslated(getLiteratures()[0], 'title', true) : t.fields.link}}</a>
		</div>
	</div>

	<div v-if="getNamespace()" class="poem-field">
		<div class="label">{{t.fields.namespace_url}}: </div>
		<a :href="getNamespace()" target="_blank">{{t.fields.namespace}} link</a>
	</div>

	<div v-if="getBio()" class="poem-field">
		<div class="label">{{t.fields.bio}}: </div>
		<div v-html="getBio()"/>
	</div>

	<div class="poem-field" v-if="poemsWriteByMe.length">
		<div class="label">{{t.fields.poems_write_by_me}}:</div>
		<ul>
			<li v-for="poem in poemsWriteByMe" :key="poem.id">
				<RouterLink :to="'/poem/'+poem.id">
					<div v-if="getVolumeTitle(poem)">
						<Popper :content="t.search.volume" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
							<img src="/volume.png" :alt="t.search.volume" class="tooltip-image"/>
						</Popper>
						<span>{{getVolumeTitle(poem)}}</span>
					</div>

					<div v-if="getPoemTitle(poem)">
						<Popper :content="t.search.poem" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
							<img src="/poem.png" :alt="t.search.poem" class="tooltip-image"/>
						</Popper>
						<span>{{getPoemTitle(poem)}}</span>
					</div>
				</RouterLink>
			</li>
		</ul>
	</div>

	<div class="poem-field" v-if="poemsWriteToMe.length">
		<div class="label">{{t.fields.poems_write_to_me}}:</div>
		<ul>
			<li v-for="poem in poemsWriteToMe" :key="poem.id">
				<RouterLink :to="'/poem/'+poem.id">
					<div v-if="getVolumeTitle(poem)">
						<Popper :content="t.search.volume" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
							<img src="/volume.png" :alt="t.search.volume" class="tooltip-image"/>
						</Popper>
						<span>{{getVolumeTitle(poem)}}</span>
					</div>

					<div v-if="getPoemTitle(poem)">
						<Popper :content="t.search.poem" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
							<img src="/poem.png" :alt="t.search.poem" class="tooltip-image"/>
						</Popper>
						<span>{{getPoemTitle(poem)}}</span>
					</div>
				</RouterLink>
			</li>
		</ul>
	</div>

	<div class="poem-field" v-if="poemsSupervised.length">
		<div class="label">{{t.fields.poems_supervised}}:</div>
		<ul>
			<li v-for="poem in poemsSupervised" :key="poem.id">
				<RouterLink :to="'/poem/'+poem.id">
					<div v-if="getVolumeTitle(poem)">
						<Popper :content="t.search.volume" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
							<img src="/volume.png" :alt="t.search.volume" class="tooltip-image"/>
						</Popper>
						<span>{{getVolumeTitle(poem)}}</span>
					</div>

					<div v-if="getPoemTitle(poem)">
						<Popper :content="t.search.poem" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
							<img src="/poem.png" :alt="t.search.poem" class="tooltip-image"/>
						</Popper>
						<span>{{getPoemTitle(poem)}}</span>
					</div>
				</RouterLink>
			</li>
		</ul>
	</div>
</template>

<script>
import Popper from "vue3-popper";
import {getArrayObject, isEmptyObject, isNumber} from "@/functions";

export default {
	inject: ['t', 'locale', 'getTranslated', 'getOriginal'],
	name: 'PersonView',
	components: {
		Popper
	},
	props: {
		poems: Array,
		locations: Array,
		volumes: Array,
		people: Array,
	},
	data() {
		return {
			id: 0,
			person: {},
			names: [],
		}
	},
	created() {
		this.getPerson();
		this.getNames();
	},
	computed: {
		poemsWriteByMe() {
			let _this = this;
			let poems = [];

			this.poems.forEach(function (poem) {
				if(poem.author && (poem.author.id === _this.person.id)) {
					poems.push({
						...poem
					});
				}
			});

			return poems;
		},
		poemsWriteToMe() {
			let _this = this;
			let poems = [];

			this.poems.forEach(function (poem) {
				if(poem.volume && poem.volume.author && poem.volume.author.id === _this.person.id) {
					poems.push({
						...poem
					});
				}
			});

			return poems;
		},
		poemsSupervised() {
			let _this = this;
			let poems = [];

			this.poems.forEach(function (poem) {
				if(poem.volume && poem.volume.supervisor && poem.volume.supervisor.id === _this.person.id) {
					poems.push({
						...poem
					});
				}
			});

			return poems;
		}
	},
	methods: {
		getPoemTitle(poem) {
			let title = "";

			if(this.getOriginal(poem, 'title')) {
				title = this.getOriginal(poem, 'title');
			}

			if(!title && poem.header) {
				title = this.getOriginal(poem.header, 'line');
			}

			return title;
		},
		getVolumeTitle(poem) {
			if(poem.volume) {
				return this.getOriginal(poem.volume, 'title');
			}

			return '';
		},
		getPerson() {
			if (this.$route.params && this.$route.params.id && isNumber(this.$route.params.id)) {
				this.id = Number(this.$route.params.id);

				this.person = {...getArrayObject(this.people, 'id', this.id)};
			}

			if (isEmptyObject(this.person)) {
				this.$router.push('/404');
			}
		},
		getNames() {
			let names = [];

			if(this.person.name_la) {
				names.push(this.person.name_la);
			}

			if(this.person.names && this.person.names.length) {
				this.person.names.forEach(function (name) {
					if(name.name) {
						names.push(name.name);
					}
					if(name.name_la) {
						names.push(name.name_la);
					}
				});
			}

			this.names = names;
		},
		getBirth() {
			return this.getTranslated(this.person, 'birth_year');
		},
		getDied() {
			return this.getTranslated(this.person, 'dead_year');
		},
		getNamespace() {
			return this.person.namespace_url ? this.person.namespace_url : ''
		},
		getBirthLocation() {
			if(this.person.birth_location) {
				return this.getTranslated(this.person.birth_location, 'name', true);
			}

			return '';
		},
		getBio() {
			return this.getTranslated(this.person, 'bio', true).replaceAll("\n", "<br />");
		},
		getStudyLocations() {
			let studyLocations = [];

			if(this.person.study_locations && this.person.study_locations.length) {
				studyLocations = [...this.person.study_locations];
			}

			return studyLocations;
		},
		getLiteratures() {
			let literatures = [];

			if(this.person.literatures && this.person.literatures.length) {
				literatures = [...this.person.literatures];
			}

			return literatures;
		},
	}
};
</script>

<style scoped lang="scss">
	.poem-field {
		font-size: 18px;

		.label {
			color: #5b5b5b;
			font-size: 14px;
			display: block;
			width: 100%;
			line-height: 1;
		}

		a {
			color: #804010;
			transition: all 250ms ease-in-out;

			&:hover {
				color: #cb946c;
			}
		}

		+ .poem-field {
			margin-top: 15px;
		}
	}
</style>