<template>
	<div class="poem-item">
		<div class="sidebar">
			<div v-if="getLocation(poem)" class="location">
                <Popper :content="t.search.location" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
					<img src="/location.png" :alt="t.search.location"/>
				</Popper>
				<RouterLink :to="'/place/'+poem.volume.published_location.id">{{getLocation(poem)}}</RouterLink>
			</div>
			<div v-if="getPublished(poem)" class="date">{{getPublished(poem)}}</div>
		</div>

		<div class="main">
			<div class="people" v-if="getAuthor(poem) || getAddressee(poem)">
				<div v-if="getAuthor(poem)">
					<RouterLink :to="'/person/'+poem.author.id">{{getAuthor(poem)}}</RouterLink>
				</div>
				<span class="chevron">&rsaquo;</span>
				<div v-if="getAddressee(poem)">
					<RouterLink :to="'/person/'+poem.volume.author.id">{{getAddressee(poem)}}</RouterLink>
				</div>
			</div>

			<div class="title">
				<div v-if="getVolumeTitle(poem)" class="volume-title">
					<Popper :content="t.search.volume" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
						<img src="/volume.png" :alt="t.search.volume"/>
					</Popper>
					<RouterLink :to="'/poem/'+poem.id">{{getVolumeTitle(poem)}}</RouterLink>
				</div>
				<div v-if="getPoemTitle(poem)" class="poem-title">
					<Popper :content="t.search.poem" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
						<img src="/poem.png" :alt="t.search.poem"/>
					</Popper>
					<RouterLink :to="'/poem/'+poem.id">{{getPoemTitle(poem)}}</RouterLink>
				</div>
			</div>

			<RouterLink class="more" :to="'/poem/'+poem.id">
				{{t.result.more}}
			</RouterLink>
		</div>
	</div>
</template>

<script>
import Popper from "vue3-popper";

export default {
	inject: ['t', 'getTranslated', 'getOriginal'],
	name: 'ResultCard',
	components: {
		Popper
	},
	props: {
		poem: Object,
	},
	methods: {
		navigate(slug) {
			this.$router.push('/'+slug);
		},
		getLocation(poem) {
			if(poem.volume && poem.volume.published_location) {
				return this.getTranslated(poem.volume.published_location, 'name', true);
			}

			return '';
		},
		getPublished(poem) {
			if(poem.volume) {
				return this.getTranslated(poem.volume, 'published_year');
			}

			return '';
		},
		getAuthor(poem) {
			if(poem.author) {
				return this.getTranslated(poem.author, 'name');
			}

			return '';
		},
		getAddressee(poem) {
			if(poem.volume && poem.volume.author) {
				return this.getTranslated(poem.volume.author, 'name');
			}

			return '';
		},
		getPoemTitle(poem) {
			let title = "";

			if(this.getOriginal(poem, 'title')) {
				title = this.getOriginal(poem, 'title');
			}

			if(!title && poem.header) {
				title = this.getOriginal(poem.header, 'line');
			}

			return title;
		},
		getVolumeTitle(poem) {
			if(poem.volume) {
				return this.getOriginal(poem.volume, 'title');
			}

			return '';
		},
	},
};
</script>

<style scoped lang="scss">
	.poem-item {
		display: flex;
		flex-flow: row;
		flex-direction: row;
		align-items: stretch;
		justify-content: center;
		flex-wrap: nowrap;
		width: 100%;
		margin-bottom: 50px;
		font-size: 20px;
		position: relative;

		@media screen and (max-width: 800px) {
			flex-flow: column;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 25px;
		}

		@media screen and (max-width: 560px) {
			font-size: 17px;
		}

		&:not(:last-child) {
			padding-bottom: 50px;

			@media screen and (max-width: 800px) {
				padding-bottom: 25px;
			}

			&:after {
				content: "";
				width: 100%;
				max-width: 460px;
				height: 1px;
				background-color: #a59360;
				opacity: 0.5;
				position: absolute;
				bottom: 0;
				margin-left: auto;
				margin-right: auto;
			}
		}

		a {
			color: #804010;
			transition: all 250ms ease-in-out;

			&:hover {
				color: #cb946c;
			}
		}

		img {
			display: inline-flex;
			width: 32px;
			height: 32px;
			margin-right: 5px;
			margin-bottom: -7px;

			@media screen and (max-width: 1430px) {
				width: 26px;
				height: 26px;
				margin-right: 5px;
				margin-bottom: -5px;
			}
		}

		.sidebar {
			display: flex;
			flex-flow: column;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap: nowrap;
			width: 320px;
			padding-right: 25px;
			border-right: 1px solid #000000;
			flex-shrink: 0;
			flex-grow: 0;

			@media screen and (max-width: 1430px) {
				width: 260px;
			}

			@media screen and (max-width: 800px) {
				width: 100%;
				padding-right: 0;
				border-right: 0;
				margin-bottom: 5px;
			}

			.location, .date {
				width: 100%;
				text-align: right;
				font-size: 24px;

				@media screen and (max-width: 1430px) {
					font-size: 20px;
				}

				@media screen and (max-width: 800px) {
					text-align: left;
				}
			}

			.date {
				@media screen and (max-width: 800px) {
					display: none;
				}
			}

			.location {
				font-weight: bold;
				margin-bottom: 8px;

				@media screen and (max-width: 800px) {
					margin-bottom: 0;
					font-size: 24px;
				}

				@media screen and (max-width: 560px) {
					font-size: 22px;
				}
			}
		}

		.main {
			flex-shrink: 1;
			flex-grow: 1;

			@media screen and (max-width: 800px) {
				width: 100%;
			}

			&:not(:first-child) {
				padding-left: 20px;

				@media screen and (max-width: 800px) {
					padding-left: 0;
				}
			}

			.people {
				display: flex;
				flex-flow: row;
				flex-direction: row;
				align-items: flex-start;
				justify-content: flex-start;
				flex-wrap: nowrap;
				width: 100%;
				line-height: 30px;
				margin-bottom: 15px;

				@media screen and (max-width: 1430px) {
					line-height: 26px;
				}

				.chevron {
					margin-left: 15px;
					margin-right: 15px;
					font-size: 38px;

					@media screen and (max-width: 1430px) {
						font-size: 32px;
					}

					@media screen and (max-width: 560px) {
						margin-left: 10px;
						margin-right: 10px;
					}
				}
			}

			.title {
				margin-bottom: 15px;

				.volume-title {
					font-weight: bold;
					margin-bottom: 10px;
					font-size: 22px;

					@media screen and (max-width: 1430px) {
						font-size: 18px;
					}

					@media screen and (max-width: 560px) {
						font-size: 18px;
					}
				}

				.poem-title {
					@media screen and (max-width: 1430px) {
						font-size: 18px;
					}
				}
			}
		}

		.more {
			margin-top: 25px;
			height: 40px;
			display: inline-flex;
			line-height: 32px;
			width: auto;
			padding: 4px 20px;
			background-color: #cb946c;
			color: #000000;
			font-weight: bold;
			font-size: 18px;
			text-transform: uppercase;
			border-radius: 4px;
			border-left: 0;
			transition: all 250ms ease-in-out;

			&:hover {
				color: #ffffff;
				background-color: #804010;
			}

			@media screen and (max-width: 800px) {
				margin-top: 15px;
			}
		}
	}
</style>
