import axios from 'axios';

export function fetch(api, callback) {
    axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/'+api+'?populate=deep', {
            headers: {
                Authorization: 'Bearer '+ process.env.VUE_APP_BEARER_TOKEN,
            },
        }).then(response => {
            if(response.data && callback) {
                callback(response.data.data);
            }
    }).catch(error => {
        console.error(error);
    });
}

export function isExistTrue(x) {
    return isDefined(x) && Boolean(x);
}

export function ifExist(have, notHave) {
    if (isDefined(have)) {
        return have;
    } else {
        if (isExist(notHave)) {
            return notHave;
        } else {
            return null;
        }
    }
}

export function isNumber(number) {
    return !isNaN(number);
}

export function isEmptySomething(value) {
    switch (true) {
        case notDefined(value):
            return true;
        case isArray(value):
            return isEmptyArray(value);
        case isNumber(value):
            return isEmptyString(value);
        case isType(value, 'string'):
            return isEmptyString(value);
        case isType(value, 'object'):
            return isEmptyObject(value);
        case isType(value, 'boolean'):
            return false;
        default:
            return true;
    }
}

export function arraySection(arr1, arr2) {
    return arr1.filter(value => arr2.includes(value));
}

export function isArray(arr) {
    return isDefined(arr) && Boolean(Array.isArray(arr));
}

export function notEmptyArray(arr) {
    return isArray(arr) && Boolean(arr.length);
}

export function isEmptyArray(arr) {
    return isArray(arr) && !arr.length;
}

export function isEmptyObject(obj) {
    if (isType(obj, 'object') && isDefined(obj)) {
        return Boolean(Object.keys(obj).length === 0 && obj.constructor === Object);
    } else {
        return true;
    }
}

export function notEmptyObject(obj) {
    return isDefined(obj) && !isEmptyObject(obj);
}

export function isExist(x) {
    return typeof (x) !== "undefined";
}

export function isDefined(x) {
    return Boolean(isExist(x) && (x !== null));
}

export function notDefined(x) {
    return !isDefined(x);
}

export function isEmptyString(x) {
    return Boolean(x === '');
}

export function isEmptyNumber(x) {
    return Boolean(x === 0);
}

export function notEmptyString(x) {
    return !isEmptyString(x);
}

export function variableType(x) {
    return typeof x;
}

export function isType(x, type) {
    return Boolean(variableType(x) === type);
}

export function findArrayObject(arr, name, value) {
    let result = false;

    if (isDefined(arr)) {
        arr.filter(obj => {
            if (isDefined(obj[name])) {
                if (isDefined(value)) {
                    if (obj[name] === value) {
                        result = true;
                    }
                } else {
                    result = true;
                }
            }
        });
    }

    return result;
}

export function getArrayObject(arr, name, value, multiple) {
    let result = false;

    if (isDefined(arr)) {
        arr.filter(obj => {
            if (isDefined(obj[name])) {
                if (isDefined(value)) {
                    if (obj[name] === value) {
                        if (multiple) {
                            result = result ? [...result, obj] : [obj];
                        } else {
                            result = obj;
                        }
                    }
                } else {
                    if (multiple) {
                        result = result ? [...result, obj] : [obj];
                    } else {
                        result = obj;
                    }
                }
            }
        });
    }

    return result;
}

export function createSlug(string) {
    return string
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '')
        .replace(/\s+/g, '_');
}