export const en = {
    lorem: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    language: {
        hu: 'Hungary',
        en: 'English',
    },
    meta: {
        title: 'Hebrew greeting poems',
    },
    menu: {
        search: 'Search',
        poem: 'Poem',
        person: 'Person',
        place: 'Place',
        not_found: 'Page Not Found',
        error: 'Error',
        page: '',
    },
    search: {
        person: 'Person',
        persons: 'Persons',
        poem: 'Poem',
        poems: 'Poems',
        people: 'Person',
        location: 'Location',
        locations: 'Locations',
        volume: 'Volume',
        volumes: 'Volumes',
        search: 'Search',
        placeholder: 'Start typing...',
        noResult: 'No results found',
        allResult: 'All poems'
    },
    result: {
        more: 'More',
        close: 'Close',
        delete: 'Delete',
        filteredBy: 'Filtered by:',
        noResult: 'No results found.',
    },
    page: {
        project_title: 'Hebrew Carmina Gratulatoria of the Hungarian Peregrines in the 17th Century',
    },
    fields: {
        author: 'Author',
        addressee: 'Addressee',
        publisher: 'Publisher',
        published_year: 'Published date',
        published_location: 'Published location',
        supervisor: 'Supervisor',
        findings: 'Findings',
        original: 'original',
        normalized: 'normalized',
        en_translation: 'English translation',
        hu_raw_translation: 'Hungarian raw translation',
        hu_literary_translation: 'Hungarian translation',
        translations: 'Available translations',
        birth: 'Born',
        died: 'Died',
        birth_location: 'Birth location',
        study_locations: 'Study locations',
        literatures: 'References',
        namespace: 'Namespace',
        namespace_url: 'Namespace contact',
        bio: 'Bio',
        link: 'Link',
        more_volume_poems: 'Other poems in the volume',
        poems_write_by_me: 'Poems written by a poet',
        poems_write_to_me: 'Poems addressed to a poet',
        poems_supervised: 'Poems for which he was the supervisor',
        connected_persons: 'Related Persons',
        connected_poems: 'Related Poems',
        images: 'Images',
        volume: 'Volume',
        catalog_number: 'Catalog number',
        rmk: 'RMK / RMNY',
    }
}