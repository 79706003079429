<template>
	<h1 class="page-title">{{ t.menu.search }}</h1>

	<div v-if="!showResults" class="search-highlight">
		<img class="logo" src="/logo.png" :alt="t.search.title"/>
		<h3 class="search-title" v-html="getTranslated(homeContent, 'text', true)"/>
	</div>

	<div class="search-outer" v-click-outside="closeSearch">
		<div class="search-inner">
			<input v-model="searchValue" name="heber_search" class="input search-input" :placeholder="t.search.search+'...'" @input="filtering"/>
			<button class="search-helper" :class="{opened: type === 'location'}" @click="toggleDropdown('location')">
				<Popper :content="t.search.locations" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
					<img src="/location.png" :alt="t.search.locations"/>
				</Popper>
			</button>
			<button class="search-helper" :class="{opened: type === 'people'}" @click="toggleDropdown('people')">
				<Popper :content="t.search.persons" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
					<img src="/people.png" :alt="t.search.persons"/>
				</Popper>
			</button>
			<button class="search-helper" :class="{opened: type === 'volume'}" @click="toggleDropdown('volume')">
				<Popper :content="t.search.volumes" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
					<img src="/volume.png" :alt="t.search.volumes"/>
				</Popper>
			</button>
			<button class="search-helper" :class="{opened: type === 'poem'}" @click="toggleDropdown('poem')">
				<Popper :content="t.search.poems" placement="top" :hover="true" :arrow="true" :offsetDistance="0">
					<img src="/poem.png" :alt="t.search.poems"/>
				</Popper>
			</button>
			<button class="all-result" @click="showAllResult">
				{{t.search.allResult}}
			</button>

			<div v-show="quickSearchDialog" class="dropdown">
				<DropdownItem v-for="item in quickSearchListFiltered" :key="item.temp_id" @click="addFilter(item)" :item="item"/>
				<div v-if="!quickSearchListFiltered.length" class="dropdown-item-empty">
					{{t.search.noResult}}
				</div>
			</div>
		</div>
		<div class="filtered" v-if="selected.length">
			<div class="filter-title">{{t.result.filteredBy}}</div>
			<FilterItem v-for="item in selected" :key="item.temp_id" :removeFilter="removeFilter" :item="item"/>
		</div>
	</div>

	<div class="results" v-if="showResults && results.length">
		<ResultCard v-for="poem in results" :key="poem.id" :poem="poem"/>
	</div>

	<div class="no-result" v-if="showResults && !results.length">
		{{t.result.noResult}}
	</div>

	<Map v-if="showResults && results.length" :poems="results"/>
</template>

<script>
import Map from "@/components/Map";
import ResultCard from "@/components/ResultCard";
import DropdownItem from "@/components/DropdownItem";
import FilterItem from "@/components/FilterItem";
import Popper from "vue3-popper";
import {findArrayObject} from "@/functions";

export default {
	components: {
		FilterItem,
		DropdownItem,
		Map,
		ResultCard,
		Popper
	},
	inject: ['t', 'locale', 'getTranslated', 'getSelected', 'setSelected', 'getAllResult', 'setAllResult'],
	name: 'SearchView',
	props: {
		poems: Array,
		locations: Array,
		volumes: Array,
		people: Array,
        homeContent: Object,
        reset: Number,
	},
	data() {
		return {
			searchValue: '',
			quickSearchDialog: false,
			quickSearchListFiltered: [],
			quickSearchListFull: [],
			selected: [],
			type: '',
			results: [],
			showResults: false,
		}
	},
	mounted() {
        this.setupQuickSearch();

        if(this.getSelected.length) {
            let _this = this;

            _this.showResults = true;

            _this.getSelected.forEach(function (item) {
                _this.addFilterPassive(item);
            });

            _this.startSearch();
		} else if(this.getAllResult) {
            this.results = [...this.poems].sort(this.locationAsc);
            this.showResults = true;
		}
	},
	watch: {
		locale() {
			if(this.showResults) {
				this.results = [...this.results].sort(this.locationAsc);
			}
		},
		reset() {
            this.resetSearch();
		}
	},
	methods: {
        resetSearch() {
            this.results = [];
            this.quickSearchListFiltered = [];
            this.quickSearchDialog = false;
            this.showResults = false;
            this.selected = [];
            this.searchValue = '';
            this.type = '';

            this.setSelected([]);
            this.setAllResult(true);
		},
		listHasItemByTypeAndId(list, type, id) {
			let founded = false;

			list.forEach(function (item) {
				if((item.type === type) && (item.id === id)) {
					founded = true;
				}
			});

			return founded;
		},
		addFilterPassive(item) {
            this.selected.push({
                ...item
            });

            this.filtering();
		},
		addFilter(item) {
			this.selected.push({
				...item
			});

            this.setSelected(this.selected);
            this.setAllResult(false);

			this.type = '';
			this.searchValue= '';

			this.filtering();
            this.startSearch();
		},
		removeFilter(item) {
			this.selected.filter((removeAble, index, arr) => {
				if ((removeAble.id === item.id) && (removeAble.type === item.type)) {
					arr.splice(index, 1);
					return true;
				}
				return false;
			});

            this.setSelected(this.selected);
            this.setAllResult(false);

			this.filtering();
			this.startSearch();
		},
		startSearch() {
			let _this = this;
			let newResult = [];

			if(this.selected.length) {
				this.poems.forEach(function (poem) {
					let show = false;

					_this.selected.forEach(function (selectedItem) {
						if(!show) {
							switch (selectedItem.type) {
								case 'people': {
									const isAuthor = poem.author && (poem.author.id === selectedItem.id);
									const isVolumeAuthor = poem.volume && poem.volume.author && (poem.volume.author.id === selectedItem.id);

									if(isAuthor || isVolumeAuthor) {
										show = true;
									}
									break;
								}
								case 'poem': {
									const isPoem = poem.id === selectedItem.id;

									if(isPoem) {
										show = true;
									}
									break;
								}
								case 'volume': {
									const isVolume = poem.volume && (poem.volume.id === selectedItem.id);

									if(isVolume) {
										show = true;
									}
									break;
								}
								case 'location': {
									const isLocation = poem.volume && poem.volume.published_location && (poem.volume.published_location.id === selectedItem.id);

									if(isLocation) {
										show = true;
									}
									break;
								}
								case 'finding': {
									const hasFinding = poem.volume && poem.volume.libraries && poem.volume.libraries.length;

									if(hasFinding) {
										poem.volume.libraries.forEach(function (_finding) {
											if(_finding.name_hu && _finding.name_hu === selectedItem.text) {
												show = true;
											}

											if(_finding.name_en && _finding.name_en === selectedItem.text) {
												show = true;
											}
										});
									}

									break;
								}
								default:
							}
						}
					});

					if(show) {
						newResult.push(poem);
					}
				});
			}

			this.results = [...newResult].sort(this.locationAsc);
			this.showResults = true;
		},
		locationAsc(a, b) {
			let locationA = 'zz';
			let locationB = 'zz';

			if(a.volume && a.volume.published_location) {
				locationA = this.getTranslated(a.volume.published_location, 'name', true);
			}

			if(b.volume && b.volume.published_location) {
				locationB = this.getTranslated(b.volume.published_location, 'name', true);
			}

			if (locationA < locationB) {
				return -1;
			}

			if (locationA > locationB) {
				return 1;
			}

			return 0;
		},
		textAsc(a, b) {
			if (a.text < b.text) {
				return -1;
			}

			if (a.text > b.text) {
				return 1;
			}

			return 0;
		},
		closeSearch() {
			this.type = '';
			this.quickSearchDialog = false;
		},
        showAllResult() {
            this.resetSearch();
            this.results = [...this.poems].sort(this.locationAsc);
            this.showResults = true;
		},
		toggleDropdown(type) {
			if(this.type === type) {
				this.quickSearchDialog = !this.quickSearchDialog;
			} else {
				this.quickSearchDialog = true;
			}

			this.type = this.quickSearchDialog ? type : '';
			this.filtering();
		},
		filtering() {
			let _this = this;
			let quickList = [];
			let searchingText = this.searchValue.length > 0;

			if(!searchingText && !this.type) {
				this.quickSearchDialog = false;
			}

			if(searchingText && !this.quickSearchDialog) {
				this.quickSearchDialog = true;
			}

			this.quickSearchListFull.forEach(function (item) {
				let selected = _this.listHasItemByTypeAndId(_this.selected, item.type, item.id);
				let show = (_this.type === '') || (_this.type === item.type);
				let founded = !searchingText || (searchingText && _this.foundedText(item.text, _this.searchValue));
				let exclusion = _this.type === 'volume' && (item.temp_id.includes("publisher_hu") || item.temp_id.includes("publisher_en"));
				let inclusion = _this.type !== 'poem' || (_this.type === 'poem' && ['title_orig','title_norm','title_en','title_hu_raw','title_hu_mu','header_orig','header_norm','header_en','header_hu_raw','header_hu_mu'].includes(item.sub_type));

				if(!selected && show && founded && !exclusion && inclusion) {
					quickList.push({...item});
				}
			});

			this.quickSearchListFiltered = quickList;
		},
		foundedText(text, value) {
			return text.toLowerCase().includes(value.toLowerCase());
		},
		createQuickSearchItem(item, type, id, text) {
			return {
				...item,
				type: type,
				sub_type: id,
				temp_id: type+'_'+id+'_'+item.id,
				text: text,
			};
		},
		setupQuickSearch() {
			let _this = this;
			let quickList = [];

			this.poems.forEach(function (item) {
				//Author
				if(item.author && item.author.id) {
					if(!_this.listHasItemByTypeAndId(quickList, 'people', item.author.id)) {
						if(item.author.name) {
							quickList.push(_this.createQuickSearchItem(item.author, 'people', 'hu', item.author.name));
						}

						if(item.author.name_la && (item.author.name !== item.author.name_la)) {
							quickList.push(_this.createQuickSearchItem(item.author, 'people', 'la', item.author.name_la));
						}

						if(item.author.names && item.author.names.length) {
							item.author.names.forEach(function (name) {
								if (name.id) {
									if (name.name) {
										quickList.push(_this.createQuickSearchItem(item.author, 'people', name.id + '_' + 'hu', name.name));
									}
									if (name.name_la && (name.name_la !== name.name)) {
										quickList.push(_this.createQuickSearchItem(item.author, 'people', name.id + '_' + 'la', name.name_la));
									}
								}
							});
						}
					}
				}

				if(!_this.listHasItemByTypeAndId(quickList, 'poem', item.id)) {
					//Title
					if (item.title_orig) {
						quickList.push(_this.createQuickSearchItem(item, 'poem', 'title_orig', item.title_orig));
					}
					if (item.title_norm && (item.title_norm !== item.title_orig)) {
						quickList.push(_this.createQuickSearchItem(item, 'poem', 'title_norm', item.title_norm));
					}
					if (item.title_en) {
						quickList.push(_this.createQuickSearchItem(item, 'poem', 'title_en', item.title_en));
					}
					if (item.title_hu_raw) {
						quickList.push(_this.createQuickSearchItem(item, 'poem', 'title_hu_raw', item.title_hu_raw));
					}
					if (item.title_hu_mu && (item.title_hu_mu !== item.title_hu_raw)) {
						quickList.push(_this.createQuickSearchItem(item, 'poem', 'title_hu_mu', item.title_hu_mu));
					}

					//Header
					if(item.header && item.header.id) {
						if (item.header.line_orig) {
							quickList.push(_this.createQuickSearchItem(item, 'poem', 'header_orig', item.header.line_orig));
						}
						if (item.header.line_norm && (item.header.line_norm !== item.header.line_orig)) {
							quickList.push(_this.createQuickSearchItem(item, 'poem', 'header_norm', item.header.line_norm));
						}
						if (item.header.line_en) {
							quickList.push(_this.createQuickSearchItem(item, 'poem', 'header_en', item.header.line_en));
						}
						if (item.header.line_hu_raw) {
							quickList.push(_this.createQuickSearchItem(item, 'poem', 'header_hu_raw', item.header.line_hu_raw));
						}
						if (item.header.line_hu_mu && (item.header.line_hu_mu !== item.header.line_hu_raw)) {
							quickList.push(_this.createQuickSearchItem(item, 'poem', 'header_hu_mu', item.header.line_hu_mu));
						}
					}

					//Footer
					if(item.footer && item.footer.id) {
						if (item.footer.line_orig) {
							quickList.push(_this.createQuickSearchItem(item, 'poem', 'footer_orig', item.footer.line_orig));
						}
						if (item.footer.line_norm && (item.footer.line_norm !== item.footer.line_orig)) {
							quickList.push(_this.createQuickSearchItem(item, 'poem', 'footer_norm', item.footer.line_norm));
						}
						if (item.footer.line_en) {
							quickList.push(_this.createQuickSearchItem(item, 'poem', 'footer_en', item.footer.line_en));
						}
						if (item.footer.line_hu_raw) {
							quickList.push(_this.createQuickSearchItem(item, 'poem', 'footer_hu_raw', item.footer.line_hu_raw));
						}
						if (item.footer.line_hu_mu && (item.footer.line_hu_mu !== item.footer.line_hu_raw)) {
							quickList.push(_this.createQuickSearchItem(item, 'poem', 'footer_hu_mu', item.footer.line_hu_mu));
						}
					}

					//Lines
					if(item.verses && item.verses.length) {
						item.verses.forEach(function (versszak) {
							if(versszak.lines && versszak.lines.length) {
								versszak.lines.forEach(function (sor) {
									if(sor.id) {
										if (sor.line_orig) {
											quickList.push(_this.createQuickSearchItem(item, 'poem', 'line_'+versszak.id+'_'+sor.id+'_'+'orig', sor.line_orig));
										}
										if (sor.line_norm && (sor.line_norm !== sor.line_orig)) {
											quickList.push(_this.createQuickSearchItem(item, 'poem', 'line_'+versszak.id+'_'+sor.id+'_'+'norm', sor.line_norm));
										}
										if (sor.line_en) {
											quickList.push(_this.createQuickSearchItem(item, 'poem', 'line_'+versszak.id+'_'+sor.id+'_'+'en', sor.line_en));
										}
										if (sor.line_hu_raw) {
											quickList.push(_this.createQuickSearchItem(item, 'poem', 'line_'+versszak.id+'_'+sor.id+'_'+'hu_raw', sor.line_hu_raw));
										}
										if (sor.line_hu_mu && (sor.line_hu_mu !== sor.line_hu_raw)) {
											quickList.push(_this.createQuickSearchItem(item, 'poem', 'line_'+versszak.id+'_'+sor.id+'_'+'hu_mu', sor.line_hu_mu));
										}
									}
								});
							}
						});
					}
				}

				if(item.volume && item.volume.id) {
					if(!_this.listHasItemByTypeAndId(quickList, 'volume', item.volume.id)) {
						//Kötet cím
						if(item.volume.title_orig) {
							quickList.push(_this.createQuickSearchItem(item.volume, 'volume', 'title_orig', item.volume.title_orig));
						}
						if(item.volume.title_norm && (item.volume.title_norm !== item.volume.title_orig)) {
							quickList.push(_this.createQuickSearchItem(item.volume, 'volume', 'title_norm', item.volume.title_norm));
						}
						if(item.volume.title_en) {
							quickList.push(_this.createQuickSearchItem(item.volume, 'volume', 'title_en', item.volume.title_en));
						}
						if(item.volume.title_hu_raw) {
							quickList.push(_this.createQuickSearchItem(item.volume, 'volume', 'title_hu_raw', item.volume.title_hu_raw));
						}
						if(item.volume.title_hu_mu && (item.volume.title_hu_mu !== item.volume.title_hu_raw)) {
							quickList.push(_this.createQuickSearchItem(item.volume, 'volume', 'title_hu_mu', item.volume.title_hu_mu));
						}

						//Lelőhelyek
						if(item.volume.libraries && item.volume.libraries.length) {
							item.volume.libraries.forEach(function (library) {
								if(library.name_hu) {
									if(!findArrayObject(quickList, 'text', library.name_hu)) {
										quickList.push(_this.createQuickSearchItem({id: item.id+'_'+item.volume.id+'_'+library.id}, 'finding', 'library_hu', library.name_hu));
									}
								}
								if(library.name_en && (library.name_en !== library.name_hu)) {
									if(!findArrayObject(quickList, 'text', library.name_en)) {
										quickList.push(_this.createQuickSearchItem({id: item.id+'_'+item.volume.id+'_'+library.id}, 'finding', 'library_en', library.name_en));
									}
								}
							});
						}

						//Kiadó
						if(item.volume.publisher_hu) {
							quickList.push(_this.createQuickSearchItem(item.volume, 'volume', 'publisher_hu', item.volume.publisher_hu));
						}

						if(item.volume.publisher_en && (item.volume.publisher_en !== item.volume.publisher_hu)) {
							quickList.push(_this.createQuickSearchItem(item.volume, 'volume', 'publisher_en', item.volume.publisher_en));
						}

						//Címzett
						if(item.volume.author && item.volume.author.id) {
							if(!_this.listHasItemByTypeAndId(quickList, 'people', item.volume.author.id)) {
								if(item.volume.author.name) {
									quickList.push(_this.createQuickSearchItem(item.volume.author, 'people', 'hu', item.volume.author.name));
								}

								if(item.volume.author.name_la && (item.volume.author.name_la !== item.volume.author.name)) {
									quickList.push(_this.createQuickSearchItem(item.volume.author, 'people', 'la', item.volume.author.name_la));
								}

								if(item.volume.author.names && item.volume.author.names.length) {
									item.volume.author.names.forEach(function (name) {
										if (name.id) {
											if (name.name) {
												quickList.push(_this.createQuickSearchItem(item.volume.author, 'people', name.id + '_' + 'hu', name.name));
											}
											if (name.name_la && (name.name_la !== name.name)) {
												quickList.push(_this.createQuickSearchItem(item.volume.author, 'people', name.id + '_' + 'la', name.name_la));
											}
										}
									});
								}
							}
						}

						//Helyszín
						if(item.volume.published_location && item.volume.published_location.id) {
							if(!_this.listHasItemByTypeAndId(quickList, 'location', item.volume.published_location.id)) {
								if(item.volume.published_location.name_hu) {
									quickList.push(_this.createQuickSearchItem(item.volume.published_location, 'location', 'hu', item.volume.published_location.name_hu));
								}

								if(item.volume.published_location.name_en && (item.volume.published_location.name_en !== item.volume.published_location.name_hu)) {
									quickList.push(_this.createQuickSearchItem(item.volume.published_location, 'location', 'en', item.volume.published_location.name_en));
								}

								if(item.volume.published_location.name_la && (item.volume.published_location.name_la !== item.volume.published_location.name_hu) && (item.volume.published_location.name_la !== item.volume.published_location.name_en)) {
									quickList.push(_this.createQuickSearchItem(item.volume.published_location, 'location', 'la', item.volume.published_location.name_la));
								}
							}
						}
					}
				}
			});

			this.quickSearchListFull = [...quickList].sort(this.textAsc);
		}
	},
};
</script>

<style lang="scss">
	.search-title {
        @media screen and (max-width: 1024px) {
            p ~ p {
                display: none;
            }
        }

		p:empty {
			margin: 0;
			padding-bottom: 20px;
		}

        a {
            color: #804010;
            white-space: nowrap;
            transition: all 250ms ease-in-out;

            &:hover {
                color: #cb946c;
            }
        }
    }
</style>

<style scoped lang="scss">
	.search-highlight {
		display: flex;
		flex-flow: row;
		flex-direction: row;
		align-items: flex-start;
		justify-content: center;
		flex-wrap: nowrap;
		width: 90%;
		max-width: 1100px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;

		@media screen and (max-width: 1024px) {
			align-items: center;
			width: 100%;
			max-width: 760px;
		}

		.logo {
			width: 200px;
			height: auto;
			flex-shrink: 0;
			flex-grow: 0;

			@media screen and (max-width: 1024px) {
				width: 160px;
			}

			@media screen and (max-width: 800px) {
				width: 120px;
			}
		}

		.search-title {
			padding-left: 30px;
			font-size: 17px;
			font-weight: normal;
			line-height: 1.4;
			text-align: justify;
			flex-shrink: 1;
			flex-grow: 1;

			@media screen and (max-width: 1024px) {
				padding-left: 15px;
				font-size: 18px;
			}
		}
	}

	.search-outer {
		display: flex;
		flex-flow: column;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: nowrap;
		width: 80%;
		max-width: 760px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 50px;

		@media screen and (max-width: 1024px) {
			width: 100%;
		}

		.search-inner {
			display: flex;
			flex-flow: row;
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap: nowrap;
			position: relative;
			z-index: 2;
			width: 100%;

			input {
				min-width: 0;
				width: auto;
				flex-grow: 1;
				flex-shrink: 1;
				height: 40px;
				border-radius: 4px 0 0 4px;
				padding: 4px 14px;

                @media screen and (max-width: 1024px) {
                    padding: 4px 8px;
				}
			}

			.search-helper {
				height: 40px;
				width: 40px;
				flex-grow: 0;
				flex-shrink: 0;
				border: 1px solid #525252;
				border-left: 0;
				transition: all 250ms ease-in-out;

				&:nth-of-type(1) {
					background-color: #d9ead3;

					&:hover, &.opened {
						background-color: #6aa84f;

						img {
							filter: invert(1);
						}
					}
				}

				&:nth-of-type(2) {
					background-color: #c9daf8;

					&:hover, &.opened {
						background-color: #6d9eeb;

						img {
							filter: invert(1);
						}
					}
				}

				&:nth-of-type(3) {
					background-color: #e6b8af;

					&:hover, &.opened {
						background-color: #e06666;

						img {
							filter: invert(1);
						}
					}
				}

				&:nth-of-type(3) {
					background-color: #e6b8af;

					&:hover, &.opened {
						background-color: #e06666;

						img {
							filter: invert(1);
						}
					}
				}

				&:nth-of-type(4) {
					background-color: #f9cb9c;

					&:hover, &.opened {
						background-color: #e69138;

						img {
							filter: invert(1);
						}
					}
				}

				img {
					display: block;
					width: 38px;
					height: 38px;
					transition: all 250ms ease-in-out;
					padding: 3px;
				}
			}

			.all-result {
				height: 40px;
				line-height: 32px;
				width: auto;
				flex-shrink: 0;
				flex-grow: 0;
				padding: 4px 14px;
				background-color: #cb946c;
				color: #000000;
				font-weight: bold;
				font-size: 18px;
				text-transform: uppercase;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				border: 1px solid #525252;
				border-left: 0;
				transition: all 250ms ease-in-out;

				@media screen and (max-width: 1024px) {
					padding: 4px 8px;
					font-size: 16px;
				}

				&:hover {
					color: #ffffff;
					background-color: #804010;
				}
			}

			.dropdown {
				position: absolute;
				left: 0;
				top: 42px;
				width: 100%;
				background-color: #ffffff;
				border: 1px solid #525252;
				border-bottom: 0;
				border-radius: 4px;
				display: flex;
				flex-flow: column;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				flex-wrap: nowrap;
				max-height: 300px;
				overflow: auto;

				.dropdown-item-empty {
					background-color: transparent;
					padding: 0 8px;
					line-height: 36px;
					font-size: 16px;
					color: #000000;
					text-align: center;
					width: 100%;
					border-bottom: 1px solid #525252;
				}
			}
		}

		.filtered {
			display: flex;
			flex-flow: row;
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap: wrap;
			width: 100%;
			margin-top: 15px;

			.filter-title {
				line-height: 20px;
				width: 100%;
				margin-bottom: 5px;
			}
		}
	}

	.results {
		display: flex;
		flex-flow: column;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: nowrap;
		width: 100%;
		margin-bottom: 40px;
	}

	.no-result {
		text-align: center;
		width: 100%;
		margin-bottom: 40px;
	}
</style>