<template>
	<div class="filter-item">
		<div class="text">
			{{ item.text }}
		</div>
		<img class="close" src="/close.svg" :alt="t.result.delete" @click="deleteItem"/>
	</div>
</template>

<script>
export default {
	inject: ['t'],
	name: 'FilterItem',
	props: {
		item: Object,
		removeFilter: Function,
	},
	methods: {
		deleteItem() {
			this.removeFilter(this.item);
		},
	},
};
</script>

<style scoped lang="scss">
	.filter-item {
		display: inline-flex;
		flex-flow: row;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: nowrap;
		background-color: #cb946c;
		color: #000000;
		padding: 4px 14px;
		border-radius: 4px;
		margin-right: 10px;
		cursor: pointer;
		font-size: 18px;
		width: auto;
		line-height: 20px;
		border: 1px solid #000000;
		margin-bottom: 10px;

		.close {
			margin-left: 10px;
			width: 16px;
			height: 16px;
			transition: all 250ms ease-in-out;

			&:hover {
				filter: invert(1);
			}
		}
	}
</style>